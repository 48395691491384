// Toggle this flag to switch between real and mocked responses
const USE_MOCKED_RESPONSE = true; // Set to true for testing, false for real fetch

export const fetchTweetEmbed = async (tweetUrl) => {
  // Mocked response with two tweets
  const mockedResponse = [
    {
      url: "https://twitter.com/heyChristiano/status/1889145996443086927",
      author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
      author_url: "https://twitter.com/heyChristiano",
      html: `<blockquote class="twitter-tweet" data-media-max-width="560"><p lang="en" dir="ltr">EVERY TIME I TRADE MEME COINS<br>ft. <a href="https://twitter.com/NessyTheRilla?ref_src=twsrc%5Etfw">@NessyTheRilla</a> <br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href="https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw">$VINE</a>! 🌱 <a href="https://t.co/GDoE84A70w">pic.twitter.com/GDoE84A70w</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href="https://twitter.com/heyChristiano/status/1889145996443086927?ref_src=twsrc%5Etfw">February 11, 2025</a></blockquote>\n<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>\n`,
      width: 550,
      height: null,
      type: "rich",
      cache_age: "3153600000",
      provider_name: "Twitter",
      provider_url: "https://twitter.com",
      version: "1.0"
    },
    {
      url: "https://twitter.com/SamuelGrubbs1/status/1889894691954737518",
      author_name: "Samuel Grubbs",
      author_url: "https://twitter.com/SamuelGrubbs1",
      html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">When the stove is lit and the grease is poppin 🔥<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/dD0OBpLsnm\">pic.twitter.com/dD0OBpLsnm</a></p>&mdash; Samuel Grubbs (@SamuelGrubbs1) <a href=\"https://twitter.com/SamuelGrubbs1/status/1889894691954737518?ref_src=twsrc%5Etfw\">February 13, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
      width: 550,
      height: null,
      type: "rich",
      cache_age: "3153600000",
      provider_name: "Twitter",
      provider_url: "https://twitter.com",
      version: "1.0"
    },
    {
      url: "https://twitter.com/heyChristiano/status/1890190211650593193",
      author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
      author_url: "https://twitter.com/heyChristiano",
      html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">.<a href=\"https://twitter.com/IGGYAZALEA?ref_src=twsrc%5Etfw\">@IGGYAZALEA</a> AT A SPELLING BEE<br>ft. <a href=\"https://twitter.com/BJCalvillo?ref_src=twsrc%5Etfw\">@BJCalvillo</a> <br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a>! 🌱 <a href=\"https://t.co/P09C2MnXlG\">pic.twitter.com/P09C2MnXlG</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href=\"https://twitter.com/heyChristiano/status/1890190211650593193?ref_src=twsrc%5Etfw\">February 14, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
      width: 550,
      height: null,
      type: "rich",
      cache_age: "3153600000",
      provider_name: "Twitter",
      provider_url: "https://twitter.com",
      version: "1.0"
  },
  {
    url: "https://twitter.com/realbatdad/status/1889105260880318732",
    author_name: "BatDad",
    author_url: "https://twitter.com/realbatdad",
    html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Rock collecting 💩<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/561wyamXui\">pic.twitter.com/561wyamXui</a></p>&mdash; BatDad (@realbatdad) <a href=\"https://twitter.com/realbatdad/status/1889105260880318732?ref_src=twsrc%5Etfw\">February 11, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
    width: 550,
    height: null,
    type: "rich",
    cache_age: "3153600000",
    provider_name: "Twitter",
    provider_url: "https://twitter.com",
    version: "1.0"
},
{
  url: "https://twitter.com/_twotymess/status/1888827725420409117",
  author_name: "TWOTYMESS (,,ﾟДﾟ)",
  author_url: "https://twitter.com/_twotymess",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">im just waiting for the <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> app to drop <br><br>🌱🌱🌱🌱 <a href=\"https://t.co/QS5XBpUsyw\">pic.twitter.com/QS5XBpUsyw</a></p>&mdash; TWOTYMESS (,,ﾟДﾟ) (@_twotymess) <a href=\"https://twitter.com/_twotymess/status/1888827725420409117?ref_src=twsrc%5Etfw\">February 10, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/_twotymess/status/1888830251569999943",
  author_name: "TWOTYMESS (,,ﾟДﾟ)",
  author_url: "https://twitter.com/_twotymess",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">omg look at what i found in the penthouse 🌱<a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/fXiRjrZeFh\">pic.twitter.com/fXiRjrZeFh</a></p>&mdash; TWOTYMESS (,,ﾟДﾟ) (@_twotymess) <a href=\"https://twitter.com/_twotymess/status/1888830251569999943?ref_src=twsrc%5Etfw\">February 10, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/BruisedOak/status/1889166854071214359",
  author_name: "yerdna",
  author_url: "https://twitter.com/BruisedOak",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">whats your take on papaya? <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/olqY6UcpYb\">pic.twitter.com/olqY6UcpYb</a></p>&mdash; yerdna (@BruisedOak) <a href=\"https://twitter.com/BruisedOak/status/1889166854071214359?ref_src=twsrc%5Etfw\">February 11, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/WeOneOfficial/status/1889712884474646578",
  author_name: "POM - For the 𝕍",
  author_url: "https://twitter.com/WeOneOfficial",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">🎵 Cause you’re amazing… Just the way you are 🎶 <br><br>Location: Karaoke bar, Tokyo 🇯🇵 <br><br>Sing 🎤 for the <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://t.co/9b2Lnaevm7\">pic.twitter.com/9b2Lnaevm7</a></p>&mdash; POM - For the 𝕍 (@WeOneOfficial) <a href=\"https://twitter.com/WeOneOfficial/status/1889712884474646578?ref_src=twsrc%5Etfw\">February 12, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/WeOneOfficial/status/1888598485005668404",
  author_name: "POM - For the 𝕍",
  author_url: "https://twitter.com/WeOneOfficial",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">I heard from a little birdie 🐦 <br><br>… That we are doing it for the <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <br><br>(Sound 🔊 ON) <a href=\"https://t.co/aNTMY6IJZN\">pic.twitter.com/aNTMY6IJZN</a></p>&mdash; POM - For the 𝕍 (@WeOneOfficial) <a href=\"https://twitter.com/WeOneOfficial/status/1888598485005668404?ref_src=twsrc%5Etfw\">February 9, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/WeOneOfficial/status/1888337785205260515",
  author_name: "POM - For the 𝕍",
  author_url: "https://twitter.com/WeOneOfficial",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Winning 2022 World Cup penalty by <a href=\"https://twitter.com/TeamMessi?ref_src=twsrc%5Etfw\">@TeamMessi</a> ⚽️ 🇦🇷 🏆 <br><br>(Sound 🔊 ON) <br><br>LIVE @ the Australian Pub, Osaka 🇯🇵 <br><br>Score it for the <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://t.co/XDONsQPrFY\">pic.twitter.com/XDONsQPrFY</a></p>&mdash; POM - For the 𝕍 (@WeOneOfficial) <a href=\"https://twitter.com/WeOneOfficial/status/1888337785205260515?ref_src=twsrc%5Etfw\">February 8, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/heyChristiano/status/1890226475611406720",
  author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
  author_url: "https://twitter.com/heyChristiano",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">ALFRED TUTORS BATMAN<br>ft. <a href=\"https://twitter.com/VincentMarcus?ref_src=twsrc%5Etfw\">@VincentMarcus</a> <br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a>! 🌱 <a href=\"https://t.co/NLQOnXlFqz\">pic.twitter.com/NLQOnXlFqz</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href=\"https://twitter.com/heyChristiano/status/1890226475611406720?ref_src=twsrc%5Etfw\">February 14, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/heyChristiano/status/1890202521366646911",
  author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
  author_url: "https://twitter.com/heyChristiano",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">WHEN YOU DATE A NEAT FREAK,<br>EVEN YOUR FRIENDS ARE SCARED.<br>ft. <a href=\"https://twitter.com/NessyTheRilla?ref_src=twsrc%5Etfw\">@NessyTheRilla</a> <br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a>! 🌱 <a href=\"https://t.co/Njom3HtrYx\">pic.twitter.com/Njom3HtrYx</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href=\"https://twitter.com/heyChristiano/status/1890202521366646911?ref_src=twsrc%5Etfw\">February 14, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/realbatdad/status/1888587569203466290",
  author_name: "BatDad",
  author_url: "https://twitter.com/realbatdad",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Two Balls<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/AxHVZdXtgg\">https://t.co/AxHVZdXtgg</a> <a href=\"https://t.co/7GRItJO4ve\">pic.twitter.com/7GRItJO4ve</a></p>&mdash; BatDad (@realbatdad) <a href=\"https://twitter.com/realbatdad/status/1888587569203466290?ref_src=twsrc%5Etfw\">February 9, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/realbatdad/status/1886799298324566122",
  author_name: "BatDad",
  author_url: "https://twitter.com/realbatdad",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Lift the seat up, or else<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/APvTfbC4SN\">pic.twitter.com/APvTfbC4SN</a></p>&mdash; BatDad (@realbatdad) <a href=\"https://twitter.com/realbatdad/status/1886799298324566122?ref_src=twsrc%5Etfw\">February 4, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/realbatdad/status/1885695862568784210",
  author_name: "BatDad",
  author_url: "https://twitter.com/realbatdad",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">The only correct way to eat jello.<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/VALL1xSxcN\">pic.twitter.com/VALL1xSxcN</a></p>&mdash; BatDad (@realbatdad) <a href=\"https://twitter.com/realbatdad/status/1885695862568784210?ref_src=twsrc%5Etfw\">February 1, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/heyalanwhatisup/status/1890230931199521131",
  author_name: "alan",
  author_url: "https://twitter.com/heyalanwhatisup",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">what if <a href=\"https://twitter.com/rus?ref_src=twsrc%5Etfw\">@rus</a> was one of us doing it for the <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> like one of us <a href=\"https://t.co/CNIR9CaEe8\">pic.twitter.com/CNIR9CaEe8</a></p>&mdash; alan (@heyalanwhatisup) <a href=\"https://twitter.com/heyalanwhatisup/status/1890230931199521131?ref_src=twsrc%5Etfw\">February 14, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/heyChristiano/status/1890464689458425883",
  author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
  author_url: "https://twitter.com/heyChristiano",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">IT’S MR. STEAL YOUR VALENTINE 💝 <br>ft. <a href=\"https://twitter.com/BJCalvillo?ref_src=twsrc%5Etfw\">@BJCalvillo</a><br>&amp; <a href=\"https://twitter.com/CorinnaKopf?ref_src=twsrc%5Etfw\">@CorinnaKopf</a><br>(Her 1st Vine!)👆<br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a>! 🌱 <a href=\"https://t.co/xIhTLSloR5\">pic.twitter.com/xIhTLSloR5</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href=\"https://twitter.com/heyChristiano/status/1890464689458425883?ref_src=twsrc%5Etfw\">February 14, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
    url: "https://twitter.com/taytayjimjim/status/1890995107626639436",
    author_name: "tay",
    author_url: "https://twitter.com/taytayjimjim",
    html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Me attempting to not make crypto my entire personality… but bring <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> back, thanks <a href=\"https://t.co/4Iglw05f8H\">pic.twitter.com/4Iglw05f8H</a></p>&mdash; tay (@taytayjimjim) <a href=\"https://twitter.com/taytayjimjim/status/1890995107626639436?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
    width: 550,
    height: null,
    type: "rich",
    cache_age: "3153600000",
    provider_name: "Twitter",
    provider_url: "https://twitter.com",
    version: "1.0"
},
{
  url: "https://twitter.com/DJpowers69/status/1891189596643316002",
  author_name: "Jonathan DJ Powers",
  author_url: "https://twitter.com/DJpowers69",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Elon did it for the <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <br><br>6 seconds Dougie Fresh you’re on…on…on <a href=\"https://t.co/u0h8VEChzJ\">pic.twitter.com/u0h8VEChzJ</a></p>&mdash; Jonathan DJ Powers (@DJpowers69) <a href=\"https://twitter.com/DJpowers69/status/1891189596643316002?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/CaliCaliSue/status/1891178994306851259",
  author_name: "Cali Sue",
  author_url: "https://twitter.com/CaliCaliSue",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Do you do it for the <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> Anon?<br><br>💚🌱💚🌱💚🌱💚🌱💚🌱💚 <a href=\"https://t.co/XHz6x9s5at\">pic.twitter.com/XHz6x9s5at</a></p>&mdash; Cali Sue (@CaliCaliSue) <a href=\"https://twitter.com/CaliCaliSue/status/1891178994306851259?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/_twotymess/status/1891185188626969003",
  author_name: "TWOTYMESS (,,ﾟДﾟ)",
  author_url: "https://twitter.com/_twotymess",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">thats why there was so much traffic<br><br>who invented traffic? eth? <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://t.co/M300FDgDFr\">pic.twitter.com/M300FDgDFr</a></p>&mdash; TWOTYMESS (,,ﾟДﾟ) (@_twotymess) <a href=\"https://twitter.com/_twotymess/status/1891185188626969003?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
    url: "https://twitter.com/a7izaidi/status/1891111228291842437",
    author_name: "Polaris",
    author_url: "https://twitter.com/a7izaidi",
    html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Travel Vine 🎥🌱<br><br>Getting lost in Tangier&#39;s ancient medina winding through narrow lanes filled with spice scents &amp; artisan workshops on my way to pay respects at Ibn Battuta&#39;s tomb. Every blue door &amp; towering minaret tells a story in this legendary traveler&#39;s hometown 🕌✨<br><br>I’m… <a href=\"https://t.co/qkero25msg\">pic.twitter.com/qkero25msg</a></p>&mdash; Polaris (@a7izaidi) <a href=\"https://twitter.com/a7izaidi/status/1891111228291842437?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
    width: 550,
    height: null,
    type: "rich",
    cache_age: "3153600000",
    provider_name: "Twitter",
    provider_url: "https://twitter.com",
    version: "1.0"
},
{
  url: "https://twitter.com/Dc5Karim/status/1891260585175601530",
  author_name: "Karim Harakat",
  author_url: "https://twitter.com/Dc5Karim",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Japan <a href=\"https://twitter.com/search?q=%24Vines&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vines</a> would be 🔥<br><br>Bring back <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://t.co/2VXsUEvI76\">pic.twitter.com/2VXsUEvI76</a></p>&mdash; Karim Harakat (@Dc5Karim) <a href=\"https://twitter.com/Dc5Karim/status/1891260585175601530?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1890944281881321526",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"et\" dir=\"ltr\">TEST TEST <a href=\"https://t.co/jTY5pXnkKk\">pic.twitter.com/jTY5pXnkKk</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1890944281881321526?ref_src=twsrc%5Etfw\">February 16, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1888288765870739823",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">reflect(reflect(reflect(reflect(reflect(reflect(...))))))<a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://twitter.com/hashtag/Vine?src=hash&amp;ref_src=twsrc%5Etfw\">#Vine</a> <a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> 🌱 <a href=\"https://t.co/R5rU2q1Oxb\">pic.twitter.com/R5rU2q1Oxb</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1888288765870739823?ref_src=twsrc%5Etfw\">February 8, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1888287857233613153",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Vines need water to grow.<br><br>Be water, my friend.<a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> <a href=\"https://twitter.com/hashtag/Vine?src=hash&amp;ref_src=twsrc%5Etfw\">#Vine</a> <a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> 🌱 <a href=\"https://t.co/Mrpe6nDgAP\">pic.twitter.com/Mrpe6nDgAP</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1888287857233613153?ref_src=twsrc%5Etfw\">February 8, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1888090118260420987",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\"><a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> is getting charged up!<br><br>🌱 <a href=\"https://twitter.com/hashtag/Vine?src=hash&amp;ref_src=twsrc%5Etfw\">#Vine</a> <a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> <a href=\"https://t.co/zNqMfMy3MU\">pic.twitter.com/zNqMfMy3MU</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1888090118260420987?ref_src=twsrc%5Etfw\">February 8, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1887614527342080290",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Upload a 6 second video of something you&#39;ve made, something from your camera roll, something funny, something creative. <br><br>Whatever it is, do it for the Vine!<a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> 🌱 <a href=\"https://t.co/Kh6rk7z7Xj\">pic.twitter.com/Kh6rk7z7Xj</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1887614527342080290?ref_src=twsrc%5Etfw\">February 6, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1887604171471204359",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Do it for the Vine! <a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> 🌱 <a href=\"https://t.co/2ybwescpaL\">pic.twitter.com/2ybwescpaL</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1887604171471204359?ref_src=twsrc%5Etfw\">February 6, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1887576834327363588",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Vines can be anything! <br><br>Vines can be computer art. <br><br>Here&#39;s my first Vine. <br><br>Show us what you&#39;ve got!<a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> <a href=\"https://t.co/cMehL59QFF\">pic.twitter.com/cMehL59QFF</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1887576834327363588?ref_src=twsrc%5Etfw\">February 6, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1887577708445532490",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Start making vines!<br><br>Tell you friends to make vines.<br><br>Repost their vines.<br><br>Tell them to tell their friends to make vines.<br><br>I&#39;m making vines, and I&#39;m going to keep making vines!<a href=\"https://twitter.com/hashtag/DoItForTheVine?src=hash&amp;ref_src=twsrc%5Etfw\">#DoItForTheVine</a> <a href=\"https://t.co/tJmrXTZMwW\">pic.twitter.com/tJmrXTZMwW</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1887577708445532490?ref_src=twsrc%5Etfw\">February 6, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/DeadlyBuffoon/status/1891525791860613596",
  author_name: "Deadly",
  author_url: "https://twitter.com/DeadlyBuffoon",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">New 2025 Vine! <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a><br><br>Share yours! Share each other’s<br><br>Do it for the Vine! <a href=\"https://t.co/CQKkZpaE7v\">pic.twitter.com/CQKkZpaE7v</a></p>&mdash; Deadly (@DeadlyBuffoon) <a href=\"https://twitter.com/DeadlyBuffoon/status/1891525791860613596?ref_src=twsrc%5Etfw\">February 17, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/DeadlyBuffoon/status/1891525524003954937",
  author_name: "Deadly",
  author_url: "https://twitter.com/DeadlyBuffoon",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">New 2025 Vine! <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a><br><br>Share yours! Share each other’s<br><br>Do it for the Vine! <a href=\"https://t.co/THXQb0PO9L\">pic.twitter.com/THXQb0PO9L</a></p>&mdash; Deadly (@DeadlyBuffoon) <a href=\"https://twitter.com/DeadlyBuffoon/status/1891525524003954937?ref_src=twsrc%5Etfw\">February 17, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
    url: "https://twitter.com/Crypt0x420/status/1891569919583539662",
    author_name: "Crypt0x",
    author_url: "https://twitter.com/Crypt0x420",
    html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Perfect loop <a href=\"https://t.co/9uz5GVtfKc\">pic.twitter.com/9uz5GVtfKc</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1891569919583539662?ref_src=twsrc%5Etfw\">February 17, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
    width: 550,
    height: null,
    type: "rich",
    cache_age: "3153600000",
    provider_name: "Twitter",
    provider_url: "https://twitter.com",
    version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1891701307364303237",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Togetherness<a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/4hzPR2t6Nh\">pic.twitter.com/4hzPR2t6Nh</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1891701307364303237?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Crypt0x420/status/1891675884597256587",
  author_name: "Crypt0x",
  author_url: "https://twitter.com/Crypt0x420",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"fr\" dir=\"ltr\">Fragmentation<a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/YhZyJGTB6x\">pic.twitter.com/YhZyJGTB6x</a></p>&mdash; Crypt0x (@Crypt0x420) <a href=\"https://twitter.com/Crypt0x420/status/1891675884597256587?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/heyChristiano/status/1891958377669923166",
  author_name: "Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸",
  author_url: "https://twitter.com/heyChristiano",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"de\" dir=\"ltr\">THE BABY BJÖRN-APULT<br><br>🔊 ( SOUND ON ) 🔊<br><br>✊ BRING BACK <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a>! 🌱 <a href=\"https://t.co/WN0PRV0knw\">pic.twitter.com/WN0PRV0knw</a></p>&mdash; Christiano Co𝕍ino 🏴‍☠️ HeyChristiano.eth 💪🐸🎸 (@heyChristiano) <a href=\"https://twitter.com/heyChristiano/status/1891958377669923166?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Oxlythames/status/1891903704824955319",
  author_name: "Oxly",
  author_url: "https://twitter.com/Oxlythames",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Can you guess the country? <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/4HWQ9TkxL1\">pic.twitter.com/4HWQ9TkxL1</a></p>&mdash; Oxly (@Oxlythames) <a href=\"https://twitter.com/Oxlythames/status/1891903704824955319?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/X_ERGO_X/status/1891900684783554946",
  author_name: "ERGO",
  author_url: "https://twitter.com/X_ERGO_X",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">🌱Do It For The <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> 🌱 <a href=\"https://t.co/XuqvdZ3PS8\">pic.twitter.com/XuqvdZ3PS8</a></p>&mdash; ERGO (@X_ERGO_X) <a href=\"https://twitter.com/X_ERGO_X/status/1891900684783554946?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/AndrewMease/status/1891608637027778852",
  author_name: "Mease",
  author_url: "https://twitter.com/AndrewMease",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Take the journey, and explore. Do it for the <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/3F1glRPmPh\">pic.twitter.com/3F1glRPmPh</a></p>&mdash; Mease (@AndrewMease) <a href=\"https://twitter.com/AndrewMease/status/1891608637027778852?ref_src=twsrc%5Etfw\">February 17, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/PopynCrypto/status/1891896259218137154",
  author_name: "Aaron | Crypto | $Vine",
  author_url: "https://twitter.com/PopynCrypto",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Had to make sure she looked right fr😩🔥<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> 🌱 <a href=\"https://t.co/n5BuTNUKE1\">pic.twitter.com/n5BuTNUKE1</a></p>&mdash; Aaron | Crypto | $Vine (@PopynCrypto) <a href=\"https://twitter.com/PopynCrypto/status/1891896259218137154?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/JumpStre3t/status/1891959109013884942",
  author_name: "Jump 🔔",
  author_url: "https://twitter.com/JumpStre3t",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">It&#39;s a Tuesday <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> 🌱<br><br>   Long day at work, Bugzy don&#39;t GAF 1 bit 🐶 <a href=\"https://t.co/swJS5cxM11\">pic.twitter.com/swJS5cxM11</a></p>&mdash; Jump 🔔 (@JumpStre3t) <a href=\"https://twitter.com/JumpStre3t/status/1891959109013884942?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/flowwithaj/status/1891654403783795109",
  author_name: "AJ Londono",
  author_url: "https://twitter.com/flowwithaj",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">6 second talent challenge by <a href=\"https://twitter.com/KettlebellDan?ref_src=twsrc%5Etfw\">@KettlebellDan</a> &amp; another clip from our movement showcase <br><br>do it for the <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/NEtpEdWePn\">pic.twitter.com/NEtpEdWePn</a></p>&mdash; AJ Londono (@flowwithaj) <a href=\"https://twitter.com/flowwithaj/status/1891654403783795109?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/DaynielPaul/status/1891918444078993874",
  author_name: "dayniel🌱",
  author_url: "https://twitter.com/DaynielPaul",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">gm ☕️ if you gm <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> 🌱 <a href=\"https://t.co/Il2fKluBhm\">pic.twitter.com/Il2fKluBhm</a></p>&mdash; dayniel🌱 (@DaynielPaul) <a href=\"https://twitter.com/DaynielPaul/status/1891918444078993874?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/lllfidelll/status/1891869545138176045",
  author_name: "Fidel",
  author_url: "https://twitter.com/lllfidelll",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Diving into crypto like… ‘Is the water safe?’ 💦😅 No idea, but here we go! <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/taVGjJgpc4\">pic.twitter.com/taVGjJgpc4</a></p>&mdash; Fidel (@lllfidelll) <a href=\"https://twitter.com/lllfidelll/status/1891869545138176045?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/flowwithaj/status/1891848677360013471",
  author_name: "AJ Londono",
  author_url: "https://twitter.com/flowwithaj",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Rope Flow can level up your throw &amp; swing patterns <br><br>do you see 👀 <a href=\"https://twitter.com/search?q=%24vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$vine</a> <a href=\"https://t.co/7gov64Ngvn\">pic.twitter.com/7gov64Ngvn</a></p>&mdash; AJ Londono (@flowwithaj) <a href=\"https://twitter.com/flowwithaj/status/1891848677360013471?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/Oxlythames/status/1891605770891866142",
  author_name: "Oxly",
  author_url: "https://twitter.com/Oxlythames",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">From the depths of hell we rise <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/K2C1rrOYA8\">pic.twitter.com/K2C1rrOYA8</a></p>&mdash; Oxly (@Oxlythames) <a href=\"https://twitter.com/Oxlythames/status/1891605770891866142?ref_src=twsrc%5Etfw\">February 17, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
  url: "https://twitter.com/PopynCrypto/status/1891905900085940341",
  author_name: "Aaron | Crypto | $Vine",
  author_url: "https://twitter.com/PopynCrypto",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">You gotta focus, especially when you&#39;re going 150+ down the highway🚀<a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> 🍃 <a href=\"https://t.co/GNbnMK06wv\">pic.twitter.com/GNbnMK06wv</a></p>&mdash; Aaron | Crypto | $Vine (@PopynCrypto) <a href=\"https://twitter.com/PopynCrypto/status/1891905900085940341?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
},
{
    url: "https://twitter.com/X_ERGO_X/status/1891900760943702480",
    author_name: "ERGO",
    author_url: "https://twitter.com/X_ERGO_X",
    html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">🌱Do It For The <a href=\"https://twitter.com/search?q=%24VINE&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$VINE</a> 🌱 <a href=\"https://t.co/8SaQ0MTVx4\">pic.twitter.com/8SaQ0MTVx4</a></p>&mdash; ERGO (@X_ERGO_X) <a href=\"https://twitter.com/X_ERGO_X/status/1891900760943702480?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
    width: 550,
    height: null,
    type: "rich",
    cache_age: "3153600000",
    provider_name: "Twitter",
    provider_url: "https://twitter.com",
    version: "1.0"
},
{
  url: "https://twitter.com/edwardmartin88/status/1891646370785718612",
  author_name: "Edward Martin",
  author_url: "https://twitter.com/edwardmartin88",
  html: "<blockquote class=\"twitter-tweet\" data-media-max-width=\"560\"><p lang=\"en\" dir=\"ltr\">Paula Cole over here doing it for the <a href=\"https://twitter.com/search?q=%24Vine&amp;src=ctag&amp;ref_src=twsrc%5Etfw\">$Vine</a> <a href=\"https://t.co/1S2ciUQQW8\">pic.twitter.com/1S2ciUQQW8</a></p>&mdash; Edward Martin (@edwardmartin88) <a href=\"https://twitter.com/edwardmartin88/status/1891646370785718612?ref_src=twsrc%5Etfw\">February 18, 2025</a></blockquote>\n<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>\n\n",
  width: 550,
  height: null,
  type: "rich",
  cache_age: "3153600000",
  provider_name: "Twitter",
  provider_url: "https://twitter.com",
  version: "1.0"
}
  ];

  if (USE_MOCKED_RESPONSE) {
    // Return the mocked response for testing
    return mockedResponse; // Return the array of tweets
  }

  // Actual fetch request
  const oembedUrl = `https://publish.twitter.com/oembed?url=${encodeURIComponent(tweetUrl)}&media_max_width=550`;

  try {
    const response = await fetch(oembedUrl, {
      method: 'GET',
      headers: {
        'Referer': 'https://publish.twitter.com/?query=' + encodeURIComponent(tweetUrl) + '&widget=Video'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.html; // Return the HTML from the response
  } catch (error) {
    console.error('Error fetching tweet:', error);
    return null; // Return null or handle the error as needed
  }
}; 