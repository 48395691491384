import { useEffect, useState, useRef } from 'react';
import './App.css';
import { fetchTweetEmbed } from './fetchTweet'; // Import the fetch function

function App() {
  const [tweets, setTweets] = useState([]); // Store the array of tweets
  const [currentTweetIndex, setCurrentTweetIndex] = useState(null); // Track the current tweet index
  const [loading, setLoading] = useState(false); // Track loading state
  const [showInput, setShowInput] = useState(false); // Track visibility of input field
  const [vineText, setVineText] = useState(''); // Store the text input for the vine
  const [previouslyShownIndices, setPreviouslyShownIndices] = useState([]); // Track previously shown tweet indices
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // Track submission success
  const scriptLoaded = useRef(false); // Ref to track if the script is loaded

  useEffect(() => {
    // Load Twitter widgets script only if not already loaded
    if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.charset = "utf-8";
      document.body.appendChild(script);
      scriptLoaded.current = true; // Mark script as loaded

      // Fetch the oEmbed data
      const getTweetEmbed = async () => {
        const fetchedTweets = await fetchTweetEmbed(); // Fetch the mocked tweets
        if (fetchedTweets) {
          setTweets(fetchedTweets); // Set the tweets in state
          
          // Select a random tweet index
          const randomIndex = Math.floor(Math.random() * fetchedTweets.length);
          setCurrentTweetIndex(randomIndex); // Set the current tweet index to a random one
          setPreviouslyShownIndices([randomIndex]); // Initialize previously shown indices
          
          // Use the tweet URL to create the tweet
          script.onload = () => {
            console.log('Fetched Tweets:', fetchedTweets); // Debugging log
            loadTweet(fetchedTweets[randomIndex].url); // Load the random tweet
          };
        } else {
          console.error('Failed to fetch tweet HTML'); // Debugging log
        }
      };

      getTweetEmbed(); // Call the function to fetch the tweet embed
    }
  }, []);

  const loadTweet = (tweetUrl) => {
    // Extract the tweet ID from the URL
    const tweetId = tweetUrl.split('/').pop(); // Get the last part of the URL
    console.log('Loading Tweet ID:', tweetId); // Debugging log

    // Clear the tweet container before loading a new tweet
    const tweetContainer = document.getElementById('tweet-container');
    tweetContainer.innerHTML = ''; // Clear existing tweet

    setLoading(true); // Set loading to true

    // Show loading spinner
    const loadingSpinner = document.createElement('div');
    loadingSpinner.className = 'loading-spinner';
    loadingSpinner.innerText = 'Loading...';
    tweetContainer.appendChild(loadingSpinner); // Append loading text to the tweet container

    window.twttr.widgets.createTweet(
      tweetId, // Pass the tweet ID instead of the URL
      tweetContainer,
      {
        theme: 'light', // Optional: Set the theme
        align: 'center' // Optional: Set alignment
      }
    ).then(() => {
      console.log('Tweet loaded successfully'); // Debugging log
      setLoading(false); // Set loading to false
      loadingSpinner.remove(); // Remove loading text after tweet is loaded
    }).catch((error) => {
      console.error('Error loading tweet:', error); // Error handling
      setLoading(false); // Set loading to false even on error
      loadingSpinner.remove(); // Remove loading text on error
    });
  };

  const handleNextTweet = () => {
    if (previouslyShownIndices.length >= tweets.length) {
      console.log('All tweets have been shown. Resetting selection.');
      return; // Exit the function
    }

    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * tweets.length);
    } while (previouslyShownIndices.includes(randomIndex)); // Ensure the tweet hasn't been shown

    setPreviouslyShownIndices((prev) => [...prev, randomIndex]); // Add the new index to the list
    setCurrentTweetIndex(randomIndex); // Update the current tweet index
    loadTweet(tweets[randomIndex].url); // Load the new random tweet
  };

  const handleStartOver = () => {
    console.log('Starting over with a new random tweet.');
    setPreviouslyShownIndices([]); // Clear previously shown indices
    handleNextTweet(); // Load a new random tweet
  };

  const handleSubmitVine = async () => {
    // Handle the submission of the vine text
    console.log('Vine submitted:', vineText);

    // Prepare the payload for the Discord webhook
    const payload = {
      content: vineText, // The message content
    };

    try {
      const response = await fetch('https://discord.com/api/webhooks/1339765653539782710/EGYEKHCvVgJ65E-icWH_uX0JJ8ffBc1-k96p0AXZKJRnQ3KPUtjEgAsLoeAXoC1vREHc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log('Message sent successfully to Discord');
        setSubmissionSuccess(true); // Set submission success to true
        setVineText(''); // Clear the input field
        setShowInput(false); // Hide the input field after submission
      } else {
        console.error('Failed to send message to Discord:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  };

  // Validation function for the input
  const isValidVineLink = (link) => {
    const regex = /^https:\/\/x\.com\/[a-zA-Z0-9_]+\/status\/\d+$/; // Regex for validating the link format
    return regex.test(link);
  };

  return (
    <div className="App">
      <header className="site-header">
        <h1 className="title">Vine is Coming Back!</h1>
        <p className="subtitle">Until then, enjoy some of these unofficial Vines.</p>
        {!showInput && ( // Only show the button if the input is not visible
          <button className="submit-vine-button" onClick={() => {
            setShowInput(true);
            setSubmissionSuccess(false); // Reset submission success when showing input
          }}>
            Submit a Vine
          </button>
        )}
        {showInput && !submissionSuccess && ( // Show input field if not submitted
          <div className="vine-input">
            <input
              type="text"
              placeholder="ex. https://x.com/heyChristiano/status/1890190211650593193"
              value={vineText}
              onChange={(e) => setVineText(e.target.value)}
            />
            <div className="button-container">
              <button 
                className="submit-vine-button" 
                onClick={handleSubmitVine} 
                disabled={!vineText || !isValidVineLink(vineText)} // Disable if input is empty or invalid
              >
                Submit
              </button>
              <button 
                className="close-button" 
                onClick={() => setShowInput(false)} // Close button action
              >
                Close
              </button>
            </div>
          </div>
        )}
        {submissionSuccess && ( // Show thank you message if submitted
          <div className="thank-you-message">
            Thank you! Your Vine is being reviewed.
          </div>
        )}
      </header>
      <main className="App-body">
        {/* Container for the tweet with added spacing */}
        <div className="tweet-container-wrapper">
          <div id="tweet-container"></div>
        </div>
        <div className="swipe-controls">
          <button onClick={previouslyShownIndices.length >= tweets.length ? handleStartOver : handleNextTweet}>
            {previouslyShownIndices.length >= tweets.length ? 'The End - Click to Reset' : 'Next'}
          </button> {/* Change button text based on state */}
        </div>
      </main>
      <footer className="footer">
        <p>
          Created by <a href="https://x.com/bitstradamus" target="_blank" rel="noopener noreferrer">@Bitstradamus</a>
        </p>
        <p>$VINE Donation Address: GRJ5PYJoJPANatirkPD49YrqMQ9Wv5VJdcZnm2zN7vzj</p>
      </footer>
    </div>
  );
}

export default App;
